import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import LocalizedLink from '../components/LocalizedLink'

import Layout from '../components/Layout'
import PageTitle from "../components/Blocks/PageTitle";
import PositionFull from '../components/Position/Default'
import GetInTouch from '../components/Blocks/GetInTouch'

const JobPostTemplate = ({pageContext}) =>  {
    const { lang, jobDetails } = pageContext
    return (
        <Layout locale={lang}>
            <PageTitle titleId='open-positions' />
            <section id='main' className='container'>
                <div className='row'>
                    <div className='col-md-2'>
                        <LocalizedLink to='_open_positions'
                            className='link--back-to-job-list'>
                            <i className='fa fa-long-arrow-left' aria-hidden='true' />&nbsp;
                            <FormattedMessage id='job-post--back-to-list'/>
                        </LocalizedLink>
                    </div>

                    <div className='col-md-10'>
                        <PositionFull details={jobDetails}/>

                        <GetInTouch />
                    </div>
                </div>
            </section>
        </Layout>
    )
}

JobPostTemplate.propTypes = {
    pageContext: PropTypes.shape({
        lang: PropTypes.string,
        jobDetails: PropTypes.shape({
            title: PropTypes.string.isRequired,
            content: PropTypes.string.isRequired,
            application_url: PropTypes.string.isRequired
        })
    })
}

export default JobPostTemplate