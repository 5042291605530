import React from 'react'
import PropTypes from 'prop-types'
import LocalizedLink from '../LocalizedLink'
import { FormattedMessage } from "react-intl"

import SEO from '../SEO'

const Position = ({ details }) => (
    <article className='node--job'>
        <SEO title={details.title}/>
        <h1 className='display-4'>{details.title}</h1>

        <div className='field--name-body' dangerouslySetInnerHTML={{ __html: details.content }} />

        <LocalizedLink to={details.application_url} className='btn btn-blue'>
            <FormattedMessage id='position--apply'/>
        </LocalizedLink>
    </article>
)

Position.propTypes = {
    details: PropTypes.shape({
        title: PropTypes.string.isRequired,
        content: PropTypes.string.isRequired,
        application_url: PropTypes.string.isRequired,
    })
}

export default Position