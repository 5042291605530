import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from "react-intl"

const PageTitle = (props) => {
    const titleId = `page-title--${props.titleId}`
    return (
        <section className='block--page-title jumbotron jumbotron-fluid'>
            <div className='container text-center'>
                <h2 id='page-title' className='display-4 text-white'>
                    <FormattedMessage id={titleId}/>
                </h2>
            </div>
        </section>
    )
}

PageTitle.propTypes = {
    titleId: PropTypes.string.isRequired
}

export default PageTitle
